/* import React, { useRef, useState } from 'react'; */
import React from 'react';
import './agenda.css';

class MeuComponente extends React.Component {
  nameInput = React.createRef();
  emailInput = React.createRef();
  phoneInput = React.createRef();
  barberSelect = React.createRef();
  styleSelect = React.createRef();
  dateButton = React.createRef();
  timeButtons = React.createRef();
  loadingRef = React.createRef();
  submitButton = React.createRef();
  successModal = React.createRef();
  alertMessage = React.createRef();

  state = {
    availableTimes: [],
    selectedDate: null, /* usa no delete tbm */
    dayButtons: [],
    timeButtons: [],
    style: '',
    phone: '',
    /* telefone: '', */
    selectedBarber: '',
    isLoading: false,
    modalMessage: '',
    showModal: false,
    alertMessage: '',
    alertType: '',
    selectedTime: null, /* usa no delete tbm */
    selectedDateButton: null,
    alert: { type: '', message: '' },
    showAppointmentForm: true,  /* teste */
  };
  
  

  closeModal = () => {
    this.successModal.current.style.display = 'none';

    // Limpe todas as informações selecionadas e escritas
    this.setState({
      availableTimes: [],
      selectedDate: null,
      selectedTime: null,
      selectedDateButton: null,
      alert: { type: '', message: '' },
    });

    // Limpe os campos de entrada
    this.nameInput.current.value = '';
    this.emailInput.current.value = '';
    this.phoneInput.current.value = '';
    this.barberSelect.current.value = '';
    this.styleSelect.current.value = '';
    this.dateButton.current.value = '';
  }

  preventNonAlphabetic = (event) => {
    const keyCode = event.keyCode ? event.keyCode : event.which;
    if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 122) && keyCode !== 32) {
        event.preventDefault();
    }
  }

  validatePhone = () => {
    this.phoneInput.current.value = this.phoneInput.current.value.replace(/[^0-9]/g, '');
  }

  formatDateUserFriendly = (date) => {
    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('pt-BR', options);
  }

  formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  }

  mapBarberToColor = (barber) => {
    return barber === 'barbeiro1' ? '6' : barber === 'barbeiro2' ? '7' : barber === 'barbeiro3' ? '5' : '';
  }

  getAvailableTimes = (date, style) => {
    const halfTimes = ['08:00-08:30', '08:30-09:00', '09:00-09:30', '09:30-10:00', '10:00-10:30', '10:30-11:00', '11:00-11:30', '11:30-12:00', '12:00-12:30', '12:30-13:00', '15:00-15:30', '15:30-16:00', '16:00-16:30', '16:30-17:00', '17:00-17:30', '17:30-18:00', '18:00-18:30', '18:30-19:00'];
    const oneTimes = ['08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00'];
    const twoTimes = ['08:00-10:00', '10:00-12:00', '14:00-16:00', '16:00-18:00', '18:00-20:00', '20:00-22:00'];
  
    const times = style === 'barba' ? oneTimes : style === 'normal' ? halfTimes : style === 'pintura' ? twoTimes : [];
  return times;
  }
  
  areFieldsFilled = () => {
    const name = this.nameInput.current.value;
    const email = this.emailInput.current.value;
    const phone = this.phoneInput.current.value;
    const selectedBarber = this.barberSelect.current.value;
    const selectedStyle = this.styleSelect.current.value;

    return name && email && phone && selectedBarber && selectedStyle;
  }

  updateTimeButtons = (selectedDate) => {
    if (!this.areFieldsFilled()) {
      this.setState({ availableTimes: [] }); // Limpa os botões de horário
      return;
    }
  
    const availableTimes = this.getAvailableTimes(selectedDate, this.styleSelect.current.value);
  this.setState({ availableTimes });

  }

            /*  */

handleDateButtonClick = async (event) => {
    if (!this.areFieldsFilled()) {
        this.displayAlert('danger', 'Por favor, preencha todos os campos obrigatórios.');
        return;
    }

    const selectedDateValue = event.target.getAttribute('data-date');
 
    /* const selectedDate = new Date(selectedDateValue + 'T00:00'); */
    /* const selectedDate = new Date(selectedDateValue + 'T00:00'); */
    
    /* this.setState({ 
        selectedDate,
        selectedDateButton: selectedDateValue
    });
    this.setState({ selectedDate }, () => {
      this.updateTimeButtons(this.state.selectedDate);
    });
    // Chame checkAvailabilityAndUpdateInterface diretamente aqui
    const startTime = selectedDate;
    const endTime = new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000); // Adiciona um dia */
    const selectedDate = new Date(Date.UTC(selectedDateValue.slice(0, 4), selectedDateValue.slice(5, 7) - 1, selectedDateValue.slice(8, 10)));
    
    this.setState({ 
        selectedDate,
        selectedDateButton: selectedDateValue
    });
    this.setState({ selectedDate }, () => {
      this.updateTimeButtons(this.state.selectedDate);
    });

    // Chame checkAvailabilityAndUpdateInterface diretamente aqui
    const startTime = selectedDate.toISOString();
    const endTime = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate() + 1)).toISOString(); // Adiciona um dia


    const timeType = this.styleSelect.current.value === 'barba' ? 'oneTimes' : this.styleSelect.current.value === 'normal' ? 'halfTimes' : this.styleSelect.current.value === 'pintura' ? 'twoTimes' : '';

    this.setState({ availableTimes: [], selectedTime: null });

    /* this.displayAlert('danger', `selectedDateValue: ${selectedDateValue} endTime: ${endTime} startTime: ${startTime} selectedDate: ${selectedDate}`); */
    
    await this.checkAvailabilityAndUpdateInterface(startTime, endTime, timeType);
    }
              
        /*  */

  handleTimeButtonClick = (event) => {
  const selectedTime = event.target.getAttribute('data-time');
  this.setState({ selectedTime });
  const timeButtons = document.querySelectorAll('#time button');
  timeButtons.forEach(button => button.classList.remove('selected'));
  event.target.classList.add('selected');
}


  formatDateToUTC = (date) => {
    const formattedDate = new Date(date);
    const offset = formattedDate.getTimezoneOffset(); // Obtém o offset em minutos
    formattedDate.setMinutes(formattedDate.getMinutes() + offset); // Ajusta para UTC+0
    return formattedDate.toISOString().slice(0, 19) + 'Z'; // Retorna a data no formato UTC+0
  }

  checkAvailabilityAndUpdateInterface = async (startTime, endTime, timeType) => {
    this.showLoading();
  
    const selectedBarber = this.barberSelect.current.value;
    const barberColorId = this.mapBarberToColor(selectedBarber);
    const phone = this.phoneInput.current.value;
  
    try {
      const response = await fetch(process.env.REACT_APP_CHECK, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientPhone: phone,
          calendarId: process.env.REACT_APP_CALENDAR_ID,
          barberColorId: barberColorId,
          start: startTime, // Já está em UTC+0
          end: endTime, // Já está em UTC+0
          timeType: timeType,
        }),
      });
  
      if (response.ok) {
        const occupiedTimes = await response.json();
    
        for (let time in occupiedTimes) {
          
          if (occupiedTimes[time] === false) {
            
            const timeButton = document.querySelector(`button[data-time="${time}"]`);
            
            if (timeButton) {
              timeButton.classList.add('occupied');
            }
          }
        }
         }  else {
        const error = await response.json();
        /* console.error(error); */
        this.displayAlert('danger', error.error);
        
        this.setState({
          availableTimes: [],
          selectedDate: null,
          selectedTime: null,
          selectedDateButton: null,
        });
        this.dateButton.current.value = '';
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.hideLoading();
    }
  }
  
  showLoading = () => {
    this.loadingRef.current.style.display = 'block';
  }

  hideLoading = () => {
    this.loadingRef.current.style.display = 'none';
  }

  openModal = (message) => {
    const lines = message.split('\n');
    let modalMessage = `<h2>${lines[0]}</h2>`;
    for (let i = 1; i < lines.length; i++) {
      const indexOfColon = lines[i].indexOf(':');
      if (indexOfColon !== -1) {
        const label = lines[i].substring(0, indexOfColon + 1);
        let value = lines[i].substring(indexOfColon + 1).trim();
  
        // Adiciona espaçamento antes de "Nome:", "Telefone:", etc.
        if (label === "Nome:" || label === "Telefone:" || label === "E-mail:") {
          value = `<span style="margin-right: 5px;">${value}</span>`;
        }
  
        modalMessage += `<p><strong>${label}</strong> ${value}</p>`;
      } else {
        modalMessage += `<p>${lines[i]}</p>`;
      }
    }
    const modalMessageElement = document.getElementById('modalMessage');
  if (modalMessageElement) {
    modalMessageElement.innerHTML = modalMessage;
  }

  this.successModal.current.style.display = 'block';
  }
  

        /*  */

  handleSubmitButtonClick = async () => {
    this.showLoading(); // Exibe o loader ao clicar no botão

    const calendarId = process.env.REACT_APP_CALENDAR_ID;
    const name = this.nameInput.current.value;
    const email = this.emailInput.current.value;
    const phone = this.phoneInput.current.value;
    const selectedBarber = this.barberSelect.current.value;
    const selectedDate = new Date(this.state.selectedDate);
    const selectedStyle = this.styleSelect.current.value;
    const selectedTime = this.state.selectedTime;

    if (!name || !email || !phone || !selectedBarber || !selectedStyle || !selectedTime) {
        this.displayAlert('danger', 'Por favor, preencha todos os campos obrigatórios.');
        this.hideLoading(); // Oculta o loader em caso de campos não preenchidos
        return;
    }

    const selectedTimeValue = selectedTime;
    const [entry, exit] = selectedTimeValue.split('-');
    const entryDate = new Date(selectedDate);
    entryDate.setUTCHours(Number(entry.split(':')[0]), Number(entry.split(':')[1]), 0, 0, 0);
    const exitDate = new Date(selectedDate);
    exitDate.setUTCHours(Number(exit.split(':')[0]), Number(exit.split(':')[1]), 0, 0, 0);
    const dateTimeStart = entryDate;
    const dateTimeEnd = exitDate;

    try {
        const response = await fetch(process.env.REACT_APP_CREATE, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                clientName: name,
                clientEmail: email,
                clientPhone: phone,
                calendarId,
                barberColorId: this.mapBarberToColor(selectedBarber),
                dateTimeStart: dateTimeStart, // Já está em UTC+0
                dateTimeEnd: dateTimeEnd, // Já está em UTC+0
            }),
        });

        if (response.ok) {
            const styleLabel = selectedStyle === 'normal' ? 'Corte Normal (30min)' : selectedStyle === 'barba' ? 'Corte + Barba (1h)' : selectedStyle === 'pintura' ? 'pintura (2h)' : '';
            const formattedDate = this.formatDateUserFriendly(new Date(exitDate.getTime()));
            const successMessage = `Compromisso criado com sucesso!\n\nNome: ${name}\nE-mail: ${email}\nTelefone: ${phone}\nBarbeiro: ${selectedBarber}\nEstilo: ${styleLabel}\nData: ${formattedDate}\nHorário: ${selectedTimeValue}`;
            this.openModal(successMessage);
        } else {
            this.displayAlert('danger', 'O barbeiro não está disponível neste horário.');
        }
    } catch (error) {
        console.error('Erro ao enviar a solicitação:', error);
        this.displayAlert('danger', 'Erro ao enviar a solicitação.');
    } finally {
        this.hideLoading(); // Oculta o loader após a resposta do servidor
    }
  }

  displayAlert = (type, message) => {
    this.setState({ alert: { type, message } });
  
    // Oculta o alerta após 5 segundos
    setTimeout(() => {
      this.setState({ alert: { type: '', message: '' } });
    }, 5000);
  }
  

  handleBarberChange = () => {
    const dateButtons = document.querySelectorAll('#date button');
    const timeButtons = document.querySelectorAll('#time button');
    dateButtons.forEach(button => button.classList.remove('selected'));
    timeButtons.forEach(button => button.classList.remove('selected'));
    this.styleSelect.current.value = '';
  
    /* if (this.state.selectedDate) {
      this.checkAvailabilityAndUpdateInterface(this.state.selectedDate);
    } */
    this.setState({ selectedDateButton: null, availableTimes: [], selectedTime: null });
  }

  handleDateSelection = (event) => {
    this.setState({ selectedDate: event.target.value }, () => {
      this.updateTimeButtons(this.state.selectedDate);
    });
  }
  
  handleStyleChange = () => {
    const dateButtons = document.querySelectorAll('#date button');
    const timeButtons = document.querySelectorAll('#time button');
    dateButtons.forEach(button => button.classList.remove('selected'));
    timeButtons.forEach(button => button.classList.remove('selected'));
  
    /* if (this.state.selectedDate) {
      this.checkAvailabilityAndUpdateInterface(this.state.selectedDate);
    } */
    this.setState({ selectedDateButton: null, availableTimes: [], selectedTime: null });
  
  }
  

  updateOccupiedTimes = (occupiedTimes) => {
    const timeButtons = this.timeButtons.current.querySelectorAll('button');
    
    timeButtons.forEach(button => {
      const time = button.getAttribute('data-time');
      if (occupiedTimes[time] === true) {
        button.classList.add('occupied');
      } else {
        button.classList.remove('occupied');
      }
    }); 
  }  

  /*  */
  handlePhoneChange = (event) => {
    this.setState({ phone: event.target.value });
}

  componentDidMount() {
    this.renderDayButtons();
    this.renderTimeButtons(this.state.style);
}

handleDeleteBarberChange = (event) => {
  this.setState({ 
    selectedBarber: event.target.value,
    availableTimes: [],
    timeButtons: [],
    style: '', // Limpa o estilo de corte
    selectedDate: '', // Limpa o dia escolhido
    selectedTime: '' // Limpa o horário escolhido
  });
  this.styleSelect.current.value = '';
}

handleDeleteStyleChange = (event) => {
  this.setState({ 
    style: event.target.value,
    selectedDate: '', // Limpa o dia escolhido
    selectedTime: '' // Limpa o horário escolhido
  });
  this.renderTimeButtons(event.target.value);
}


renderDayButtons = () => {
    const dayButtons = [];
    for (let i = 1; i < 14; i++) {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + i);

        // Verifica se o dia atual não é sábado (6) nem domingo (0)
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            dayButtons.push({
                date: currentDate,
                text: this.formatDateUserFriendly(currentDate)
            });
        }
    }
    this.setState({ dayButtons });
}

handleButtonClick = (date) => {
    this.setState({ selectedDate: date });
}

getAvailable = (style) => {
  const halfTimes = ['08:00-08:30', '08:30-09:00', '09:00-09:30', '09:30-10:00', '10:00-10:30', '10:30-11:00', '11:00-11:30', '11:30-12:00', '12:00-12:30', '12:30-13:00', '15:00-15:30', '15:30-16:00', '16:00-16:30', '16:30-17:00', '17:00-17:30', '17:30-18:00', '18:00-18:30', '18:30-19:00'];
  const oneTimes = ['08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00'];
  const twoTimes = ['08:00-10:00', '10:00-12:00', '14:00-16:00', '16:00-18:00', '18:00-20:00', '20:00-22:00'];
  return style === 'barba' ? oneTimes : style === 'normal' ? halfTimes : style === 'pintura' ? twoTimes : [];
}

renderTimeButtons = (style) => {
  const availableTimes = this.getAvailable(style);
  this.setState({ timeButtons: availableTimes });
}

handleDeleteTimeButtonClick = (time) => {
  this.setState({ selectedTime: time });
}

handleDeleteClick = async (event) => {
  event.preventDefault();
  this.setState({ isLoading: true });

  const calendarId = 'f4392d051f66e3988107791ea88f04fe83f4641849fe2ffe8773c08e32bebaa6@group.calendar.google.com';
  const { phone, selectedBarber, selectedDate, style, selectedTime } = this.state;

  const barberColorId = this.mapBarberToColor(selectedBarber);

  if (!phone || !selectedBarber || !style || !selectedTime) {
      this.setState({
          alertType: 'danger',
          alertMessage: 'Por favor, preencha todos os campos obrigatórios.',
          isLoading: false
      });
      return;
  }

  const selectedTimeValue = selectedTime;
  const [entry, exit] = selectedTimeValue.split('-');
  const entryDate = new Date(selectedDate);
  entryDate.setUTCHours(Number(entry.split(':')[0]), Number(entry.split(':')[1]), 0, 0, 0);
  const exitDate = new Date(selectedDate);
  exitDate.setUTCHours(Number(exit.split(':')[0]), Number(exit.split(':')[1]), 0, 0, 0);
  const dateTimeStart = entryDate.toISOString();
  const dateTimeEnd = exitDate.toISOString();

  try {
      const response = await fetch(process.env.REACT_APP_DELETE, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              clientPhone: phone,
              calendarId: calendarId,
              barberColorId: barberColorId,
              dateTimeStart: dateTimeStart,
              dateTimeEnd: dateTimeEnd,
          })
      });

      if (response.ok) {
          const styleLabel = style === 'normal' ? 'Corte Normal (30min)' : style === 'barba' ? 'Corte + Barba (1h)' : style === 'pintura' ? 'pintura (2h)' : '';
          const formattedDate = this.formatDateUserFriendly(new Date(exitDate.getTime()));
          const successMessage = `Compromisso apagado com sucesso!\n\nTelefone: ${phone}\nBarbeiro: ${selectedBarber}\nEstilo: ${styleLabel}\nData: ${formattedDate}\nHorário: ${selectedTimeValue}`;
          
          this.deleteModal(successMessage);
          /* this.setState({
              alertType: 'success',
              alertMessage: successMessage,
              isLoading: false
          }); */
      } else {
          this.setState({
              alertType: 'danger',
              alertMessage: 'Erro ao enviar a solicitação.',
              isLoading: false
          });
      }
  } catch (error) {
      this.setState({
          alertType: 'danger',
          alertMessage: 'Erro ao enviar a solicitação.',
          isLoading: false
      });
    } finally {
      this.setState({
        isLoading: false
    });
  }
}


validateDeletePhone = () => {
  this.setState(prevState => ({
      phone: prevState.phone.replace(/[^0-9]/g, '')
  }));
}

deleteModal = (message) => {
  const lines = message.split('\n');
  let modalMessage = `<h2>${lines[0]}</h2>`;
  for (let i = 1; i < lines.length; i++) {
      const indexOfColon = lines[i].indexOf(':');
      if (indexOfColon !== -1) {
          const label = lines[i].substring(0, indexOfColon + 1);
          let value = lines[i].substring(indexOfColon + 1).trim();

          // Adiciona espaçamento antes de "Nome:", "Telefone:", etc.
          if (label === "Nome:" || label === "Telefone:" || label === "E-mail:") {
              value = `<span style="margin-right: 5px;">${value}</span>`;
          }

          modalMessage += `<p><strong>${label}</strong> ${value}</p>`;
      } else {
          modalMessage += `<p>${lines[i]}</p>`;
      }
  }
  this.setState({ modalMessage, showModal: true });
}

deleteCloseModal = () => {
  this.setState({
    selectedDate: '',
    timeButtons: [],
    phone: '',
    style: '',
    selectedBarber: '',
    isLoading: false,
    modalMessage: '',
    showModal: false,
    alertMessage: '',
    alertType: '',
  });

  // Verifique se a referência atual é null antes de definir o valor
  if (this.phoneInput.current) {
    this.phoneInput.current.value = '';
  }
  if (this.barberSelect.current) {
    this.barberSelect.current.value = '';
  }
  if (this.styleSelect.current) {
    this.styleSelect.current.value = '';
  }
}

componentDidUpdate(prevProps, prevState) {
  if (this.state.alertMessage !== prevState.alertMessage || this.state.alertType !== prevState.alertType) {
    this.deleteDisplayAlert(this.state.alertType, this.state.alertMessage);
  }
}

deleteDisplayAlert = (type, message) => {
  this.setState({ alertType: type, alertMessage: message });
  setTimeout(() => {
      this.setState({ alertMessage: '', alertType: '' });
  }, 5000);
}

showAppointmentForm = () => {
    this.setState({ showAppointmentForm: true });
};

showDeleteForm = () => {
    this.setState({ showAppointmentForm: false });
};

  /*  */

  render() {

    const { type, message } = this.state.alert;

    // Gera as datas para os próximos 14 dias
    const dates = Array.from({ length: 14 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() + i + 1);
        return date;
      }).filter(date => date.getDay() !== 0 && date.getDay() !== 6);

    return (
      <>
        <section className="agendamento">
          <div className="header">
              <img src="https://img.freepik.com/vetores-premium/modelo-de-logotipo-vintage-de-barbearia_441059-25.jpg" alt=""/>
          </div>
          {/*  */}

          <div className='abas'>
                <button onClick={this.showAppointmentForm} className={this.state.showAppointmentForm ? 'button-clicked' : ''}>Agendar</button>
                <button onClick={this.showDeleteForm} className={this.state.showAppointmentForm ? '' : 'button-clicked'}>Excluir</button>
          </div>
                {this.state.showAppointmentForm ? (
                    <div className="formulario">

                    <div className="opcoes">
                        <label htmlFor="name">Nome:</label>
                        <input type="text" id="name" ref={this.nameInput} maxLength="20" onKeyPress={this.preventNonAlphabetic} required/>
                    </div>
      
                    <div className="opcoes">
                        <label htmlFor="email">E-mail:</label>
                        <input type="email" id="email" ref={this.emailInput} maxLength="35" required/>
                    </div>
      
                    <div className="opcoes">
                        <label htmlFor="phone">Telefone:</label>
                        <input type="text" id="phone" ref={this.phoneInput} maxLength="9" onInput={this.validatePhone} required/>
                    </div>
      
                    <div className="opcoes">
                        <label htmlFor="barber">Barbeiro:</label>
                        <select id="barber" ref={this.barberSelect} onChange={this.handleBarberChange} required>
                            <option value="">Escolha o Barbeiro</option>
                            <option value="barbeiro1">Barbeiro 1</option>
                            <option value="barbeiro2">Barbeiro 2</option>
                            <option value="barbeiro3">Barbeiro 3</option>
                        </select>
                    </div>
      
                    <div className="opcoes">
                        <label htmlFor="style">Estilo de Corte:</label>
                        <select id="style" ref={this.styleSelect} onChange={this.handleStyleChange} required>
                            <option value="">Escolha o Estilo de Corte</option>
                            <option value="normal">Corte Normal (30min)</option>
                            <option value="barba">Corte + Barba (1h)</option>
                            <option value="pintura">Pintura (2h)</option>
                        </select>
                    </div>
      
                    <div className="opcoes">
                        <label htmlFor="date">Dia:</label>
                        <div id="date" className="date-container" ref={this.dateButton}>
                          {dates.map(date => (
                            <button 
                              key={date} 
                              data-date={this.formatDate(date)} 
                              onClick={this.handleDateButtonClick}
                              className={this.state.selectedDateButton === this.formatDate(date) ? 'selected' : ''}
                            >
                              {this.formatDateUserFriendly(date)}
                            </button>
                          ))}
                        </div>
                    </div>
      
      <div className="opcoes">
          <label htmlFor="time">Horário:</label>
          <div id="time" className="time-container" ref={this.timeButtons}>
        {this.state.availableTimes.map(time => (
          <button key={time} data-time={time} onClick={this.handleTimeButtonClick}>
            {time}
          </button>
        ))}
        </div>
      </div>
                      
      <div className="opcoes">
          <button id="submit-button" className="btn" ref={this.submitButton} onClick={this.handleSubmitButtonClick}><span>Agendar</span></button>
          <div className="loading" id="loading" ref={this.loadingRef}>
              <div className="loader" id="loader"></div>
          </div>
      </div>
                    
      {message && (
        <div id="alert-message" className={`alert alert-${type}`} ref={this.alertMessage}>
          {message}
        </div>
      )}
                           
          <div id="successModal" className="modal" ref={this.successModal}>
            <div className="modal-content">
              <div id="modalMessage" className="modal-message">
                {this.state.modalMessage}
              </div>
              <button onClick={this.closeModal}>OK</button>
            </div>
          </div>
                
    </div>

    ) : (

        <div id="deleteAppointmentForm" className="delete-form" style={{ display: 'grid' }} >

          <div className="opcoes">
            <label htmlFor="phone">Telefone:</label>
            <input type="text" ref={this.phoneInput} onChange={this.handlePhoneChange} maxLength="9" required/>
          </div>

          <div className="opcoes">
            <label htmlFor="barber">Barbeiro:</label>
            <select id="delete-barber" ref={this.barberSelect} required onChange={this.handleDeleteBarberChange}>
                <option value="">Escolha o Barbeiro</option>
                <option value="barbeiro1">Barbeiro 1</option>
                <option value="barbeiro2">Barbeiro 2</option>
                <option value="barbeiro3">Barbeiro 3</option>
            </select>
          </div>

          <div className="opcoes">
          <label htmlFor="style">Estilo de Corte:</label>
            <select id="delete-style" ref={this.styleSelect} required onChange={this.handleDeleteStyleChange}>
              <option value="">Escolha o Estilo de Corte</option>
              <option value="normal">Corte Normal (30min)</option>
              <option value="barba">Corte + Barba (1h)</option>
              <option value="pintura">Pintura (2h)</option>
            </select>
          </div>

          <div className="opcoes delete-appointment">
          <label htmlFor="date">Dia:</label>
          <div id="delete-date" className="date-container">
              {this.state.dayButtons.map((button, index) => (
                  <button
                      key={index}
                      onClick={() => this.handleButtonClick(button.date)}
                      className={this.state.selectedDate === button.date ? 'selected' : ''}
                  >
                      {button.text}
                  </button>
              ))}
            </div>
          </div>

            <div className="opcoes delete-appointment">
                <label htmlFor="time">Horário:</label>
                <div id="delete-time" className="time-container">
                    {this.state.timeButtons.map((time, index) => (
                        <button
                            key={index}
                            onClick={() => this.handleDeleteTimeButtonClick(time)}
                            className={`delete-appointment-time ${this.state.selectedTime === time ? 'selected' : ''}`}>
                            {time}
                        </button>
                    ))}
                </div>
            </div>

            <div className="opcoes">
                <button type="submit" className="delete-btn" onClick={this.handleDeleteClick}>
                    Deletar Agendamento
                </button>
                <div className="delete-loading" id="delete-loading" style={{display: this.state.isLoading ? 'block' : 'none'}}>
                    <div className="loader" id="loader"></div>
                </div>
            </div>

            <div id="delete-successModal" className="modal" style={{display: this.state.showModal ? 'block' : 'none'}}>
                <div className="modal-content">
                    <div id="delete-modalMessage" className="modal-message" dangerouslySetInnerHTML={{ __html: this.state.modalMessage }}></div>
                    <button onClick={this.deleteCloseModal}>OK</button>
                </div>
            </div>

            <div id="delete-alert-message" className={`alert ${this.state.alertType}`} style={{display: this.state.alertMessage ? 'block' : 'none'}}>
                {this.state.alertMessage}
            </div>
            
      
        </div>
    )} 
        </section>

      </>
    );
  }
}

export default MeuComponente;
