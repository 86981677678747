import React from "react";
import bannerimg from './img/Theuz.Banner.png';
import './banner.css';

class Banner extends React.Component {

    render(){
        
        return(
            <>
                <main className="banner">                
                    <img src={bannerimg} alt="Banner Theuz" />
                </main>
            </>
        )
    }
}

export default Banner;