import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Theuz from './theuz/theuz';
import Agenda from './agenda/agenda';
import Reserva from './reserva/reserva';
import Banner from './TheuzBanner/theuzBanner';

function App() {

  return (
    <div>
      
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Theuz/>}/>
          <Route path='/agenda' element={<Agenda/>} />
          <Route path='/reserva' element={<Reserva />} />
          <Route path='/bann' element={<Banner />} />      
        </Routes>
      </BrowserRouter>

    </div>
  );
};

export default App;
