import React from 'react';

import MeuComponente from './teste';
/* import AgendaMain from './Agenda-main'; */

function Agenda(){
    return(
        <>
        
        <MeuComponente />
        {/* <AgendaMain /> */}

        </>
    )
}

export default Agenda;