import React, { useState } from 'react';
import './theuz.css';

import theuzBig from './imgTheuz/theuzBig.png';

/* Web */
import barba from './imgTheuz/img-barba.png';
import dente from './imgTheuz/img-dente.png';
import carro from './imgTheuz/img-carro.png';
import comida from './imgTheuz/img-comida.png';

/* LandPage */


/* Agenda */
import agenda1 from './imgTheuz/img-agenda1.png';
import agenda2 from './imgTheuz/img-agenda2.png';
import agenda3 from './imgTheuz/img-agenda3.png';
import agenda4 from './imgTheuz/img-agenda4.png';

/* SEO */
import blocked from './imgTheuz/blocked.png';

/* APP */


import BackToTop from "./backToTop";
import ContactForm from "./form";


function Theuz(){

    const [modalOpen, setModalOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const openModal = (src) => {
        setImageSrc(src);
        setModalOpen(true);
      };
      

      const closeModal = () => {
        setModalOpen(false);
      };

    return(
        <>
    <header className='theuz-header'>
        <a href="#produto">produtos</a>

        <BackToTop />

        <a href="#contato">contato</a>
    </header>

    <main className='theuz-main'>
        
        <section className="inicio">
            <img src= {theuzBig} alt="Logo" />
            {/* <h1>Paginas Web, Land Page e Serviços de Reserva de Horário</h1> */}
            <h1>Paginas Web, Land Page, SEO on-page e App Mobile</h1>
        </section>

        <article className='theuz-article'>
            <section className="produtos colorido" id="produto">
                <div className="info">
                    <div className="titulo">
                        <h2>Pagina Web</h2>
                    </div>
                    <div className="lista">
                        <ul>
                            <li>Design Profissional e Personalizado</li>
                            <li>Responsividade e Acessibilidade</li>
                            <li>Funcionalidades Avançadas de Integração</li>
                            <li>Otimização para Motores de Busca</li>
                        </ul>
                    </div>
                </div>
                    <div className="imagem-junta">
                        <img src={barba} loading='lazy' fetchpriority="true" alt="site de barbeiro" onClick={() => openModal(barba)}/>
                        <img src={dente} alt="site de dentista" onClick={() => openModal(dente)}/>
                        <img src={carro} alt="site de carros" onClick={() => openModal(carro)}/>
                        <img src={comida} alt="site de restaurante" onClick={() => openModal(comida)}/>
                    </div>
            {modalOpen && (
                <div className="modal" onClick={closeModal}>
                <img src={imageSrc} alt="Imagem em tamanho real" className="modal-image" />
                </div>
            )}
            </section>
            
            

            <section className="produtos">
                <div className="info reverse">
                    <div className="lista">
                        <ul>
                            <li>Foco em Conversão</li>
                            <li>Design Atraente e Impactante</li>
                            <li>Teste e Otimização Contínua</li>
                        </ul>
                    </div>
                    <div className="titulo">
                        <h2>Land Page</h2>
                    </div>
                </div>
                <div className="imagem-junta">
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                </div>
            </section>

            <section className="produtos colorido">
                <div className="info">
                    <div className="titulo">
                        <h2>Reserva de Horário</h2>
                    </div>
                    <div className="lista">
                        <ul>
                            <li>Agendamento Simplificado</li>
                            <li>Personalização e Flexibilidade</li>
                            <li>Notificações Automatizadas</li>
                            <li>Integração com Calendários</li>
                        </ul>
                    </div>
                </div>
                <div className="imagem-junta">
                    <img src={agenda1} alt="utilização do serviço" onClick={() => openModal(agenda1)}/>
                    <img src={agenda2} alt="utilização do serviço" onClick={() => openModal(agenda2)}/>
                    <img src={agenda3} alt="utilização do serviço" onClick={() => openModal(agenda3)}/>
                    <img src={agenda4} alt="utilização do serviço" onClick={() => openModal(agenda4)}/>
                </div>
            </section>

            <section className="produtos">
                <div className="info reverse">
                    <div className="lista">
                        <ul>
                            <li>Análise de Palavras-chave</li>
                            <li>Otimização On-Page</li>
                            <li>Link Building Estratégico</li>
                        </ul>
                    </div>
                    <div className="titulo">
                        <h2>SEO</h2>
                    </div>
                </div>
                <div className="imagem-junta">
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                </div>
                <div className="block"></div>
            </section>

            <section className="produtos colorido">
                <div className="info">
                    <div className="titulo">
                        <h2>Aplicativo Mobile</h2>
                    </div>
                    <div className="lista">
                        <ul>
                            <li>Aplicativos rápidos e eficientes</li>
                            <li>Soluções sob medida para suas necessidades</li>
                            <li>Design intuitivo e fácil de usar</li>
                            <li>Aplicativos para Android e iOS</li>
                        </ul>
                    </div>
                </div>
                <div className="imagem-junta">
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                    <img src={blocked} alt="None" onClick={() => openModal(blocked)}/>
                </div>
                <div className="block"></div>
            </section>

        </article>

        <article className='theuz-sobre'>
            <h1>Sobre Nós</h1>

            <h3>Quem somos</h3>
            <p>Somos especialistas em transformar o espaço online em um campo de oportunidades para nossos clientes, com o foco em design web, criamos soluções digitais que abrem caminhos para o sucesso. Estamos empenhados em criar um impacto positivo, aumentando vendas e otimizando processos  para auxiliar no crescimento e a eficiência de nossos parceiros.</p>

            <h3>Nossa Missão</h3>
            <p>Tornar a tecnologia acessível e prática, com design atrativo e experiência de usuário fluida, impulsionando o sucesso online dos nossos clientes.</p>

            <h3>Nossos Valores:</h3>
            <ul>
                <li><strong>Inovação:</strong> Buscamos constantemente novas tendências e tecnologias.</li>
                <li><strong>Qualidade:</strong> Dedicamos atenção meticulosa a cada detalhe dos projetos.</li>
                <li><strong>Integridade:</strong> Mantemos transparência total, fortalecendo a confiança dos nossos clientes.</li>
            </ul>

            <h3>Nossos Objetivos:</h3>
            <ul>
                <li><strong>Facilitar o seu Sucesso:</strong> Simplificar a jornada digital para torná-la mais rentável.</li>
                <li><strong>Parcerias Duradouras:</strong> Estabelecer relações de longo prazo baseadas em confiança e resultados.</li>
                <li><strong>Atendimento Excepcional:</strong> Garantir o melhor suporte para você e seu negócio.</li>
            </ul>

            <p>Cada cliente é uma estrela no universo digital, e nós estamos aqui para fazer você brilhar.<strong>Vamos juntos nessa jornada rumo ao topo!</strong></p>

        </article>


        <ContactForm />
    
    </main>

    <footer className='theuz-footer'>
        <p>direitos reservados</p>
    </footer>
        </>
    )
}

export default Theuz;